import { delay, filter, map, switchMap, tap } from "rxjs/operators";
import { TActionsDefinitionsList } from "./TAction";
import { action, routeAction } from "./actionFunctions";
import { array as extArray } from "../../../../shared/src/utilByDomainGroupExport";
import { CasesPage, CasesCaseViewForm, CasesDayViewForm, TCasesCaseViewForm, TCasesCaseForm, CasesCaseForm, TCasesCaseCreateAdhocForm, TCasesPageView, TAdhocTaskForms, TCasesCaseTaskFormAdhocForDayView, TCasesDayViewForm, TCasesCaseViewFilterChaseStatus } from "../../../../domain/codecs/form/CasesForm";
import { formOperation } from "../../wrappers/formOperation";
import { Observable, from, of } from "rxjs";
import { DateTime } from "luxon";
import { TStateLens } from "../../../../domain/codecs/state";
import { TCodecSetState, TGetCodecState } from "../applyActions";
import { requireExhaustive } from "../../../../shared/src/util";
import { foldFormOnSuccess } from "../../functions/form/foldFormOnSuccess";
import { pipe } from "fp-ts/lib/pipeable";
import { array } from "fp-ts";

type TActionParams = {
    lens: TStateLens, 
    setState: TCodecSetState, 
    getState: TGetCodecState
};

export const actions: TActionsDefinitionsList = [
    routeAction("VIEW_CRM_LEGAL_NEW_CASES", (obs$: Observable<unknown>, lens, setState, getState) => {
        obs$
            .pipe(
                tap(() =>
                    setState(
                        lens.cases_page.set({
                            ...CasesPage.newDefault(),
                            view: "case",
                            cases_view_form: {
                                ...CasesCaseViewForm.newDefault(),
                                status: "submitting",
                                edited: {
                                    ...CasesCaseViewForm.newDefault().edited,
                                    filters: {
                                        ...CasesCaseViewForm.newDefault().edited.filters,
                                        status: sessionUser.is_chaser_staff
                                            ? "any_instructed"
                                            : "any_instructed_excluding_abeyance_and_completed",
                                        assigned_to: defaultCasesViewFormAssignedToUserId,
                                        chase: defaultCasesViewFormChaseMode,
                                        sail_homes_listing_only: sessionUser.is_chaser_staff,
                                    },
                                    order: defaultCasesViewFormOrder,
                                    pagination: {
                                        limit: 20,
                                        offset: 0,
                                    },
                                },
                            },
                            day_view_form: {
                                ...CasesDayViewForm.newDefault(),
                                edited: {
                                    ...CasesDayViewForm.newDefault().edited,
                                    filters: {
                                        ...CasesDayViewForm.newDefault().edited.filters,
                                        assigned_to: defaultDayViewFormAssignedToUserId,
                                        date: {
                                            type: "iso",
                                            date: DateTime.utc().setZone("Europe/London").toISODate(),
                                        },
                                    },
                                    pagination: {
                                        limit: 20,
                                        offset: 0,
                                    },
                                },
                            },
                            cases_view_open_case_id: null,
                        }),
                    )
                ),
                switchMap(() => getCases$(lens, setState, getState)),
            ).subscribe();
    }),
    action("CRM_NEW_CASES_CASE_VIEW_FILTER_CHANGE", (obs$: Observable<TCasesCaseViewForm>, lens, setState, getState) => {
        obs$.pipe(
            filter(() => lens.cases_page.cases_view_form.status.get()(getState()) !== "submitting"),
            tap(() => setCaseViewFormSubmitting(lens, setState)),
            tap((form) => setState(lens.cases_page.cases_view_form.edited.filters.set(form.edited.filters))),
            switchMap(() => resetCaseViewPagination$(lens, setState)),
            switchMap(() => clearCasesViewOpenCaseId$(lens, setState)),
            switchMap(() => getCases$(lens, setState, getState)),
        ).subscribe()
    }),
    action("CRM_NEW_CASES_CASE_VIEW_SORTING_CHANGE", (obs$: Observable<TCasesCaseViewForm>, lens, setState, getState) => {
        obs$.pipe(
            filter(() => lens.cases_page.cases_view_form.status.get()(getState()) !== "submitting"),
            tap(() => setCaseViewFormSubmitting(lens, setState)),
            tap((form) => setState(lens.cases_page.cases_view_form.edited.order.set(form.edited.order))),
            switchMap(() => resetCaseViewPagination$(lens, setState)),
            switchMap(() => clearCasesViewOpenCaseId$(lens, setState)),
            switchMap(() => getCases$(lens, setState, getState)),
        ).subscribe()
    }),
    action("CRM_NEW_CASES_CASE_VIEW_LOAD_MORE", (obs$: Observable<undefined>, lens, setState, getState) => {
        obs$.pipe(
            tap(() => setState(lens.cases_page.cases_view_form.edited.pagination.offset.set(
                lens.cases_page.cases_view_form.edited.pagination.offset.get()(getState()) + 20,
            ))),
            switchMap(() => getCases$(lens, setState, getState)),
        ).subscribe()
    }),
    action("CRM_NEW_CASES_CASE_VIEW_CASE_SUBMIT", (obs$: Observable<TCasesCaseForm>, lens, setState, getState) => {
        obs$.pipe(
            filter(({status}) => status !== "submitting"),
            map((form) => ({
                ...form,
                status: "submitting" as "submitting",
            })),
            tap((form) => setState(lens.cases_page.cases_view_form.children.case_forms.where(({edited}) => edited.id === form.edited.id).set(form))),
            switchMap((form) => formOperation("UpdateCasesCase", {
                ...form,
                children: CasesCaseForm.newDefault().children,
            })),
            tap((response) => setState(lens.cases_page.cases_view_form.children.case_forms.where(({edited}) => edited.id === response.edited.id).set({
                ...response,
                children: lens.cases_page.cases_view_form.children.case_forms.where(({edited}) => edited.id === response.edited.id).children.get()(getState())
            }))),
        ).subscribe()
    }),
    action("CRM_NEW_CASES_CASE_VIEW_COMMENT_CHANGE", (obs$: Observable<TCasesCaseForm>, lens, setState) => {
        obs$.pipe(
            tap((form) => setState(lens.cases_page.cases_view_form.children.case_forms.where(({edited}) => edited.id === form.edited.id).set({
                ...form,
                status: "requiresSubmission",
            }))),
        ).subscribe()
    }),
    action("CRM_NEW_CASES_CASE_VIEW_OPEN_CASE_ID_CHANGE", (obs$: Observable<string | null>, lens, setState) => {
        obs$.pipe(
            tap((value) => setState(lens.cases_page.cases_view_open_case_id.set(value))),
        ).subscribe()
    }),
    action("CRM_NEW_CASES_VIEW_CHANGE", (obs$: Observable<TCasesPageView>, lens, setState, getState) => {
        obs$.pipe(
            tap((view) => setState(lens.cases_page.view.set(view))),
            switchMap((view) =>
                view === "case" ? resetCaseViewPagination$(lens, setState)
                    .pipe(
                        tap(() => setCaseViewFormSubmitting(lens, setState)),
                        switchMap(() => clearCasesViewOpenCaseId$(lens, setState)),
                        switchMap(() => getCases$(lens, setState, getState)),
                    )
                : view === "day" ? resetDayViewPagination$(lens, setState)
                    .pipe(
                        tap(() => setDayViewFormSubmitting(lens, setState)),
                        switchMap(() => getDayTasks$(lens, setState, getState)),
                    )
                : requireExhaustive(view),
            ),
        ).subscribe()
    }),
    action("CRM_NEW_CASES_SUBMIT_NEW_TASK", (obs$: Observable<[string, TCasesCaseCreateAdhocForm]>, lens, setState, getState) => {
        obs$.pipe(
            tap(([caseID, taskForm]) => setState(
                lens.cases_page.cases_view_form.children.case_forms
                .where((form) => form.original.id === caseID)
                .children.create_adhoc_form.status
                .set("submitting")
            )),
            switchMap(([caseID, taskForm]) => 
                from(formOperation("CreateCasesCaseAdhoc", taskForm))
                .pipe(
                    tap(foldFormOnSuccess(
                        ({ status }) => setState(
                            lens.cases_page.cases_view_form.children.case_forms
                            .where((form) => form.original.id === caseID)
                            .children.create_adhoc_form.status
                            .set(status)
                    ),
                        (response) => setState(
                            lens.cases_page.cases_view_form.children.case_forms
                            .where((form) => form.original.id === caseID)
                            .children.create_adhoc_form
                            .set(response)
                        )
                    )),

                    filter((response) => response.status === "success"),
                    delay(500),
                    switchMap(() => getCase$(caseID, { lens, setState, getState })),
                )
            ),
            
        ).subscribe()
    }),
    action("CRM_NEW_CASES_ARCHIVE_TASK", (obs$: Observable<[string, TAdhocTaskForms]>, lens, setState, getState) => {
        obs$.pipe(
            switchMap(([caseID, taskForm]) => 
                from(formOperation("DeleteCasesCaseAdhoc", taskForm))
                .pipe(
                    switchMap(() => getCase$(caseID, { lens, setState, getState })),
                )
            ),
        ).subscribe()
    }),
    action("CRM_NEW_CASES_UPDATE_TASK", (obs$: Observable<[string, TAdhocTaskForms]>, lens, setState, getState) => {
        obs$.pipe(
            switchMap(([caseID, taskForm]) => 
                from(formOperation("UpdateCasesCaseTask", taskForm))
                .pipe(
                    switchMap(() => getCase$(caseID, { lens, setState, getState })),
                )
            ),
        ).subscribe()
    }),
    action("CRM_NEW_CASES_DAY_VIEW_LOAD_MORE", (obs$: Observable<undefined>, lens, setState, getState) => {
        obs$.pipe(
            tap(() => setState(lens.cases_page.day_view_form.edited.pagination.offset.set(
                lens.cases_page.day_view_form.edited.pagination.offset.get()(getState()) + 20,
            ))),
            switchMap(() => getDayTasks$(lens, setState, getState)),
        ).subscribe()
    }),
    action("CRM_NEW_CASES_ARCHIVE_DAY_TASK", (obs$: Observable<TAdhocTaskForms>, lens, setState, getState) => {
        obs$.pipe(
            switchMap((taskForm) => formOperation("DeleteCasesCaseAdhoc", taskForm)),
            tap(foldFormOnSuccess(
                (response) => setState(
                    lens.cases_page.day_view_form.children.task_forms
                    .where((task) => task.original.id === response.original.id)
                    .set(response as TCasesCaseTaskFormAdhocForDayView)
                ),
                (response) => setState(
                    lens.cases_page.day_view_form.set(
                        getDayFormAfterTaskPruning(
                            lens.cases_page.day_view_form.get()(getState()),
                            [response.original.id]
                        )
                    )
                )
            ))
        ).subscribe()
    }),
    action("CRM_NEW_CASES_UPDATE_DAY_TASK", (obs$: Observable<TAdhocTaskForms>, lens, setState, getState) => {
        obs$.pipe(
            switchMap((taskForm) =>
                from(formOperation("UpdateCasesCaseTask", taskForm))
                    .pipe(
                        tap((response) => setState(
                            lens.cases_page.day_view_form.children.task_forms
                            .where((task) => task.original.id === response.original.id)
                            .set({
                                ...response,
                                children: taskForm.children,
                            } as TCasesCaseTaskFormAdhocForDayView)
                        )),
                        delay(300),
                        tap((response) => setState(
                            lens.cases_page.day_view_form.children.task_forms
                            .where((task) => task.original.id === response.original.id)
                            .set({
                                ...response,
                                status: "untouched",
                                children: taskForm.children,
                            } as TCasesCaseTaskFormAdhocForDayView)
                        )),
                        filter((response) => !!response.original.is_done),
                        delay(300),
                        tap((response) => setState(
                            lens.cases_page.day_view_form.set(
                                getDayFormAfterTaskPruning(
                                    lens.cases_page.day_view_form.get()(getState()),
                                    [response.original.id]
                                )
                            )
                        ))
                    )
            ),
        ).subscribe()
    }),
    action("CRM_NEW_CASES_DAY_VIEW_FILTER_CHANGE", (obs$: Observable<TCasesDayViewForm>, lens, setState, getState) => {
        obs$.pipe(
            filter(() => lens.cases_page.day_view_form.status.get()(getState()) !== "submitting"),
            tap(() => setDayViewFormSubmitting(lens, setState)),
            tap((form) => setState(lens.cases_page.day_view_form.edited.filters.set(form.edited.filters))),
            switchMap(() => resetDayViewPagination$(lens, setState)),
            switchMap(() => getDayTasks$(lens, setState, getState)),
        ).subscribe()
    }),
];

const getCase$ = (caseID: string, { lens, setState }: TActionParams ) => 
    from(formOperation("GetCasesCaseViewCase", getCaseForm(caseID)))
        .pipe(
            tap((response) => setState(
                lens.cases_page.cases_view_form.children.case_forms
                .where((form) => form.original.id === response.original.id)
                .set(response)                                                                                                                                                                                                                                                                      
            ))
        )
;

const getCaseForm = (id: string) => {
    const defaultForm = CasesCaseForm.newDefault();
    return {
        ...defaultForm,
        edited: { ...defaultForm.edited, id }
    }
}

const getCases$ = (lens: TStateLens, setState: TCodecSetState, getState: TGetCodecState) =>
    from(
        formOperation("GetCasesCaseView", {
            ...lens.cases_page.cases_view_form.get()(getState()),
            children: CasesCaseViewForm.newDefault().children,
        })
    )
        .pipe(
            delay(100),
            tap((response) =>
                lens.cases_page.cases_view_form.edited.pagination.offset.get()(getState()) === 0
                    ? setState(lens.cases_page.cases_view_form.set(response))
                    : setState(lens.cases_page.cases_view_form.set({
                        ...response,
                        children: {
                            ...response.children,
                            case_forms: [
                                ...lens.cases_page.cases_view_form.children.case_forms.get()(getState()),
                                ...response.children.case_forms,
                            ]
                        }
                    })),
            ),
        );

const resetCaseViewPagination$ = (lens: TStateLens, setState: TCodecSetState) =>
    of(
        setState(lens.cases_page.cases_view_form.edited.pagination.set({
            offset: 0,
            limit: 20
        })),
    );

const clearCasesViewOpenCaseId$ = (lens: TStateLens, setState: TCodecSetState) =>
    of(
        setState(lens.cases_page.cases_view_open_case_id.set(null)),
    );

const resetDayViewPagination$ = (lens: TStateLens, setState: TCodecSetState) =>
    of(
        setState(lens.cases_page.day_view_form.edited.pagination.set({
            offset: 0,
            limit: 20
        })),
    );

const getDayTasks$ = (lens: TStateLens, setState: TCodecSetState, getState: TGetCodecState) =>
    from(
        formOperation("GetCasesDayView", {
            ...lens.cases_page.day_view_form.get()(getState()),
            children: CasesDayViewForm.newDefault().children,
        })
    )
        .pipe(
            delay(100),
            tap((response) =>
                lens.cases_page.day_view_form.edited.pagination.offset.get()(getState()) === 0
                    ? setState(lens.cases_page.day_view_form.set(response))
                    : setState(lens.cases_page.day_view_form.set({
                        ...response,
                        children: {
                            ...response.children,
                            task_forms: [
                                ...lens.cases_page.day_view_form.children.task_forms.get()(getState()),
                                ...response.children.task_forms,
                            ]
                        }
                    })),
            ),
        );

const setCaseViewFormSubmitting = (lens: TStateLens, setState: TCodecSetState) => setState(lens.cases_page.cases_view_form.status.set("submitting"));

const setDayViewFormSubmitting = (lens: TStateLens, setState: TCodecSetState) => setState(lens.cases_page.day_view_form.status.set("submitting"));

const getDayFormAfterTaskPruning = (form: TCasesDayViewForm, taskIDsToPrune: Array<string>): TCasesDayViewForm => {
    const prunedTasks = pipe(
        form.children.task_forms,
        array.filter((email) => !extArray.contains(email.original.id)(taskIDsToPrune)),
    );
    const numberPruned = form.children.task_forms.length - prunedTasks.length;

    return {
        ...form,
        children: {
            ...form.children,
            task_forms: prunedTasks,
            available_count: form.children.available_count - numberPruned
        }
    };
}

const defaultCasesViewFormAssignedToUserId =
    sessionUser.user_role === "sail_legal_authoriser_staff" ? sessionUser.id
    : null;

const defaultDayViewFormAssignedToUserId =
    sessionUser.user_role !== "user" ? sessionUser.id
    : null;

const defaultCasesViewFormChaseMode: TCasesCaseViewFilterChaseStatus =
    sessionUser.user_role === "sail_legal_authoriser_staff" ? "workable"
    : sessionUser.is_chaser_staff ? "chase"
    : "any";

const defaultCasesViewFormOrder =
    sessionUser.is_chaser_staff
        ? {
            column: "chase_mode",
            direction: "ascending",
        } as const
        : null;
